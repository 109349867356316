export const regionIdForPath = (regions, path) => {
    const slug = regionSlugForPath(path);
    if (slug) {
        const region = regions.find(r => r.slug === slug);
        if (region) {
            return region.id;
        }
    }
    return null;
}

export const regionSlugForPath = (path) => {
    const parts = path.split('/');
    if (parts.length >= 3) {
        return parts[1];
    }
    return null;
}