import React from 'react';
import {
    List, Datagrid, TextField,
    SimpleForm, TextInput, FormDataConsumer, minLength,
    required, ReferenceInput, AutocompleteInput, RadioButtonGroupInput
} from 'react-admin';
import {isAdminUser} from "../helpers/isAdminUser";
import {Edit, Create} from './forms';
import {ListPagination} from "./pagination";


export const UsersList = props => (
    <List {...props} bulkActionButtons={false} sort={{field: 'name', order: 'ASC'}} pagination={<ListPagination/>}>
        <Datagrid rowClick="edit">
            <TextField source="name"/>
            <TextField source="email"/>
            <TextField source="accessLevel"/>
            <TextField source="region.name" label={"Region"} sortable={false}/>
        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>{record ? record.name : ''}</span>;
};
const UserForm = (props) => {
    const isAdmin = isAdminUser();

    let passwordValidate = [minLength(6)];
    if (props.mode === 'create') {
        passwordValidate.push(required());
    }

    return <SimpleForm {...props} redirect={"list"}>
        <TextInput source="name" validate={[required()]}/>
        <TextInput source="email" validate={[required()]}/>
        <RadioButtonGroupInput disabled={!isAdmin} source="accessLevel" defaultValue={"Regional"} choices={[
            {id: 'Regional', name: 'Regional'},
            {id: 'Admin', name: 'Admin'},
            {id: 'Scraper', name: 'Scraper'},
        ]}/>
        <FormDataConsumer>
            {({formData, ...rest}) => {
                const regionRequired = formData.accessLevel === 'Regional';
                const validate = regionRequired ? [required()] : [];

                return <ReferenceInput label="Region" source="regionId" reference={"region"}
                                       allowEmpty={!regionRequired} validate={validate}>
                    <AutocompleteInput optionText="name"
                                       translateChoice={false}
                    />
                </ReferenceInput>
            }}
        </FormDataConsumer>

        <TextInput source="password" type="password" validate={passwordValidate}/>
        <TextInput source="loginKey" validate={[]}/>
    </SimpleForm>;
};

export const UserEdit = props => {
    return <Edit {...props} title={<EditTitle/>} undoable={false}>
        <UserForm mode={"edit"}/>
    </Edit>;
}

export const UserCreate = props => (
    <Create {...props} title={<EditTitle/>} undoable={false}>
        <UserForm mode={"create"}/>
    </Create>
);