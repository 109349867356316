import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import AppBar from '@material-ui/core/AppBar';
import {EventDay} from "./EventDay";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = {
    root: {
        width: '100%',
        flexGrow: 1
    },
    tab: {
        maxWidth: 70,
        minWidth: 70
    },
    future: {
        backgroundColor: '#eeeeee'
    }
};

const StyledBadge = withStyles({
    badge: {
        marginRight: -7
    }
})(Badge);


class RawEventDays extends React.Component {
    constructor(props) {
        super(props);

        const regex = /[#]([^&?]*)/g;
        const found = regex.exec(window.location.href);
        let value = 0;
        if (found && found.length >= 2) {
            const date = moment(found[1]);
            value = Math.max(props.eventDays.findIndex((e) => date.isSame(e.eventDay, 'day')), 0);
        }

        this.state = {
            value: value
        };
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    formatToEventDayString(moment) {
        return moment.format("YYYY-MM-DD");
    }

    render() {
        const { classes, eventDays, selectedRegionSlug } = this.props;
        const { value } = this.state;

        const eventDay = eventDays[value];

        const context = `/#${this.formatToEventDayString(eventDay.eventDay)}`;

        let i = 0;

        return <div className={classes.root}>
            <AppBar position="static" color={"inherit"}>
            <Tabs value={value}
                  onChange={this.handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  scrollable={true}
            >
                {eventDays.map(e => {
                    const weekday = e.eventDay.format("ddd");
                    const eventDayString = this.formatToEventDayString(e.eventDay);
                    const pendingCount = e.events.filter(e => e.isReviewPending).length;
                    let label = weekday;
                    if (pendingCount > 0) {
                        label = <StyledBadge badgeContent={pendingCount} color={"primary"}>{weekday}</StyledBadge>;
                    }

                    return <Tab
                        key={e.eventDay}
                        className={`${classes.tab} ${i++ >= 7 ? classes.future : null}`}
                        label={label}
                        component={Link}
                        to={`#${eventDayString}`}/>
                })}
            </Tabs>
            </AppBar>
            <TabContainer>
                    <EventDay events={eventDay.events} eventDay={eventDay.eventDay} selectedRegionSlug={selectedRegionSlug} context={context} />
            </TabContainer>
        </div>
    }
}

export const EventDays = withStyles(styles)(RawEventDays);