import gql from 'graphql-tag';
import {createApiClient} from "./apiClient";


const GET_BADGES_BY_REGION = (regionId) => {
    return gql`
        query {
            region(id: "${regionId}") {
                badges {
                    pendingEventsCount
                    pendingVenuesCount
                    pendingGenresCount
                }
            }
        }
    `
};

export const fetchBadges = function(dispatch, regionId) {
    createApiClient().query({
        query: GET_BADGES_BY_REGION(regionId)
    }).then(result => {
        dispatch({type: 'FETCHED_BADGES', badges: result.data.region.badges, regionId: regionId});
    });
}