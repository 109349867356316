import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "react-router-dom";
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment-timezone';
import {HideButton} from "./HideButton";
import Chip from '@material-ui/core/Chip';

const styles = {
    title: {
        display: "flex"
    },
    time: {
        textAlign: "right",
        flexGrow: 1
    },
    genresAndTags: {
    },
    genres: {
        fontStyle: 'italic'
    },
    genre: {
        paddingRight: 5
    },
    tags: {
    },
    tag: {
      margin: 1
    },
    hidden: {
        opacity: 0.25
    },
    promoted: {
        backgroundColor: '#ddd181'
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    }
};

const formatTime = (isoString) => {
    return moment.utc(isoString, "YYYY-MM-DDTHH:mm").format("H:mm");
}

const formatDate = (isoString) => {
    return moment.utc(isoString, "YYYY-MM-DDTHH:mm").format("D. MMM Y");
}


const RawEventListItem = ({ event, classes, eventResource, context, selectedRegionSlug, withVenue }) => {
    let time = "";

    if (event.hasTime) {
        time = formatTime(event.from);
        if (event.until) {
            time += " - " + formatTime(event.until);
        }
    }

    const genres = event.genres.map(g => {
        const style = {
            position: 'relative',
            marginRight: 5
        };
        return <Tooltip key={g.name} title={g.category.name}><span className={classes.genre} style={style}><span style={{
            position: 'absolute',
            top: 0,
            bottom: -5,
            left: 0,
            right: 0,
            borderBottom: `3px solid #${g.category.color}`
        }}></span><span>{g.name}</span></span></Tooltip>;
    });

    const tags = event.tags.map(tag => {
        return <Chip label={tag} className={classes.tag} />;
    });

    const className = event.isReviewPending ? null : event.isHidden ?
        classes.hidden
        : event.isPromoted ? classes.promoted : null;

    return <ListItem key={event.id}
                     className={className}
                     button
                     component={Link}
                     to={encodeURI(`/r/${selectedRegionSlug}/events/${event.id}?context=${context}`)}>
        <ListItemText
            primary={
                <div className={classes.title}>
                        <strong>
                            {withVenue ? event.venue.name : formatDate(event.from)}
                        </strong>
                    {withVenue && <Link className={classes.link} to={encodeURI(`r/${selectedRegionSlug}/venues/${event.venue.id}?context=${context}`)}>
                        <LinkIcon/>
                    </Link>}
                    <span className={classes.time}>{time}</span>

                </div>
            }
            secondary={<>
              <span>{event.description}</span>
              <div className={classes.genresAndTags}>
                <span className={classes.genres}>{genres}</span>
                <span className={classes.tags}>{tags}</span>
              </div>
            </>}
        />
        {(event.isHidden && !event.isReviewPending) ||
        <ListItemSecondaryAction>
            <HideButton selectedRegionSlug={selectedRegionSlug} event={event}/>
        </ListItemSecondaryAction>}
    </ListItem>
};

export const EventListItem = withStyles(styles)(RawEventListItem);
