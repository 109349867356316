import {Admin} from 'react-admin';
import {DenkmalLayout} from "./DenkmalLayout";
import {Dashboard} from "./dashboard/Dashboard";
import {RegionCreate, RegionEdit, RegionsList} from "./forms/regions";
import React from "react";
import {Resource} from 'react-admin';
import PlaceIcon from '@material-ui/icons/Place';
import EventIcon from '@material-ui/icons/Event';
import VenueIcon from '@material-ui/icons/Business';
import UserIcon from '@material-ui/icons/Face';
import GenreCategoriesIcon from '@material-ui/icons/QueueMusic';
import GenreIcon from '@material-ui/icons/MusicNote';
import {VenueCreate, VenueEdit, VenuesList} from "./forms/venues";
import {getRegionsFromState} from "./helpers/getRegionsFromState";
import { connect } from 'react-redux';
import {EventCreate, EventEdit, EventsList} from "./forms/events";
import {LoginPage} from "./authentication/LoginPage";
import {GenreCategoriesList, GenreCategoryCreate, GenreCategoryEdit} from "./forms/genreCategories";
import {GenreCreate, GenreEdit, GenresList} from "./forms/genres";
import {UserCreate, UserEdit, UsersList} from "./forms/users";

class RawAdmin extends React.Component {
    render() {
        const regionSlug = this.props.regionSlug;

        let resources = [];

        if (regionSlug) {
            resources = resources.concat([
                <Resource key={"r/" + regionSlug + "/venues"} name={"r/" + regionSlug + "/venues"} list={VenuesList} edit={VenueEdit} create={VenueCreate} icon={VenueIcon} options={{label: 'Venues'}}/>,
                <Resource key={"r/" + regionSlug + "/events"} name={"r/" + regionSlug + "/events"} list={EventsList} edit={EventEdit} create={EventCreate} icon={EventIcon} options={{label: 'Events'}} />,
                <Resource key={"r/" + regionSlug + "/genreCategories"} name={"r/" + regionSlug + "/genreCategories"} list={GenreCategoriesList} edit={GenreCategoryEdit} create={GenreCategoryCreate} icon={GenreCategoriesIcon} options={{label: 'Genre Categories'}} />,
                <Resource key={"r/" + regionSlug + "/genres"} name={"r/" + regionSlug + "/genres"} list={GenresList} edit={GenreEdit} create={GenreCreate} icon={GenreIcon} options={{label: 'Genres'}} />,
                <Resource key={"r/" + regionSlug + "/eventDays"} name={"r/" + regionSlug + "/eventDays"} />
                ]);
        }

        resources = resources.concat([
            <Resource key="region" name="region" list={RegionsList} edit={RegionEdit} create={RegionCreate}
                      icon={PlaceIcon}/>,
            <Resource key="user" name="user" list={UsersList} edit={UserEdit} create={UserCreate}
                      icon={UserIcon}/>
        ]);

        return (<Admin appLayout={DenkmalLayout}
                       history={this.props.history}
                       authProvider={this.props.authProvider}
                       dataProvider={this.props.dataProvider}
                       title={"Denkmal Admin"}
                       loginPage={LoginPage}
                       dashboard={Dashboard}>
            {resources}
        </Admin>)
    }
}

export const DenkmalAdmin = connect(
    (state, ownProps) => {
        let regionSlug = null;

        const regions = getRegionsFromState(state);
        if (state.selectedRegionSlug) {
            regionSlug = state.selectedRegionSlug;
        }

        return {
            ...ownProps,
            regionSlug: regionSlug,
            regions: regions
        }
    },
    (dispatch, ownProps) => {
        return {

        }
    })(RawAdmin);
