import gql from 'graphql-tag';
import {GET_LIST} from "react-admin";
import moment from 'moment-timezone';


const GET_EVENTDAYS_BY_REGION = (regionId, eventDayStrings) => {
    return gql`
        query {
            region(id: "${regionId}") {
                events(eventDays:${JSON.stringify(eventDayStrings)}, withHidden: true) {
                    id
                    description
                    from
                    until
                    hasTime
                    genres {
                        id
                        name
                        category { name color }
                    }
                    tags
                    venueId
                    links {
                        label
                        url
                    }
                    venue {
                        id
                        name
                        url
                    }
                    isHidden
                    isPromoted
                    isReviewPending
                }
            }
        }
    `;
}

export const eventDaysProvider = function(type, {eventDayStrings}, client, regionId) {
    switch(type) {
        case GET_LIST: {
            return client.query({
                query: GET_EVENTDAYS_BY_REGION(regionId, eventDayStrings)
            }).then(result => {
                let data = [];
                for(let i=0; i<eventDayStrings.length; i++) {
                    data.push({
                        id: eventDayStrings[i],
                        eventDay: moment(eventDayStrings[i]),
                        events: result.data.region.events[i]
                    });
                }

                return {
                    data: data,
                    total: data.length
                };
            });
        }
        default: {

        }
    }
};
