import gql from 'graphql-tag';
import {GET_ONE, GET_LIST, UPDATE, CREATE, DELETE, GET_MANY} from 'react-admin';
import {listOptionsFromParams} from "./listOptionsFromParams";
import {fetchBadges} from "./badges";


const GET_VENUES_BY_REGION = (regionId, {page, perPage, sortField, ascending}, search) => {
    return gql`
        query {
            region(id: "${regionId}") {
                venuesList(listOptions: {pagination: {page: ${page}, perPage: ${perPage}}, sort: {field: "${sortField}", ascending: ${ascending}}}, search: "${search}") {
                    venues {
                        id
                        name
                        address
                        url
                        email
                        latitude
                        longitude
                        isSuspended
                        ignoreScraper
                        isReviewPending
                        facebookPageId
                        twitter
                        aliases{name}
                    }
                    count
                }
            }
        }
    `;
}
const GET_VENUE = (id) => gql`
    query {
        venue(id: "${id}") {
            id
            name
            address
            url
            email
            latitude
            longitude
            isSuspended
            ignoreScraper
            isReviewPending
            facebookPageId
            twitter
            aliases{name}
            events{
                id
                description
                from
                until
                hasTime
                genres {
                    id
                    name
                    category { name color }
                }
                tags
                venueId
                links {
                    label
                    url
                }
                venue {
                    id
                    name
                    url
                }
                isHidden
                isPromoted
                isReviewPending
            }
        }
    }
`;

const CHANGE_VENUE = gql`
    mutation ChangeVenue($id: ID!, $name: String, $address: String, $url: URL, $email: String,
    $latitude: Float, $longitude: Float, $isSuspended: Boolean, $ignoreScraper: Boolean,
    $isReviewPending: Boolean, $facebookPageId: String, $twitter: String, $aliases: [String!]) {
        changeVenue(id: $id, name: $name, address: $address, url: $url, email: $email,
            latitude: $latitude, longitude: $longitude, isSuspended: $isSuspended, ignoreScraper: $ignoreScraper,
            isReviewPending: $isReviewPending, facebookPageId: $facebookPageId, twitter: $twitter, aliases: $aliases) {
            id
            name
            address
            url
            email
            latitude
            longitude
            isSuspended
            ignoreScraper
            isReviewPending
            facebookPageId
            twitter
            aliases{name}
        }
    }
`;
const ADD_VENUE = gql`
    mutation AddVenue($regionId: ID!, $id: ID, $name: String!, $address: String, $url: URL, $email: String,
    $latitude: Float, $longitude: Float, $isSuspended: Boolean, $ignoreScraper: Boolean,
    $isReviewPending: Boolean, $facebookPageId: String, $twitter: String) {
        addVenue(regionId: $regionId, id: $id, name: $name, address: $address, url: $url, email: $email,
            latitude: $latitude, longitude: $longitude, isSuspended: $isSuspended, ignoreScraper: $ignoreScraper,
            isReviewPending: $isReviewPending, facebookPageId: $facebookPageId, twitter: $twitter) {
            id
            name
            address
            url
            email
            latitude
            longitude
            isSuspended
            ignoreScraper
            isReviewPending
            facebookPageId
            twitter
        }
    }
`;

const DELETE_VENUE = gql`
    mutation DeleteVenue($venueId: ID!) {
        deleteVenue(id: $venueId)
    }
`;


const transformAliases = (aliases) => {
    return aliases.map(a => a.name);
}

export const venueProvider = function(type, params, client, regionId, {dispatch}) {
    switch(type) {
        case GET_LIST: {
            const search = params.filter.q ? '%' + params.filter.q + '%' : "";
            return client.query({
                query: GET_VENUES_BY_REGION(regionId, listOptionsFromParams(params), search)
            }).then(result => {
                return {
                    data: result.data.region.venuesList.venues,
                    total: result.data.region.venuesList.count
                };
            });
        }
        case GET_ONE: {
            return client.query({
                query: GET_VENUE(params.id)
            }).then(result => {
                return {
                    data: result.data.venue
                };
            });
        }
        case GET_MANY: {
            // used for event -> venue selection
            return client.query({
                query: GET_VENUE(params.ids[0])
            }).then(result => {
                return {
                    data: [result.data.venue]
                };
            });
        }
        case UPDATE: {
            return client.mutate({
                variables: {
                    ...params.data,
                    aliases: transformAliases(params.data.aliases)
                },
                mutation: CHANGE_VENUE
            }).then(result => {
                fetchBadges(dispatch, regionId);
                return {
                    data: result.data.changeVenue
                }
            });
        }
        case CREATE: {
            return client.mutate({
                variables: {
                    regionId: regionId,
                    ...params.data
                },
                mutation: ADD_VENUE
            }).then(result => {
                return {
                    data: result.data.addVenue
                }
            });
        }
        case DELETE: {
            return client.mutate({
                variables: {
                    venueId: params.id
                },
                mutation: DELETE_VENUE
            }).then(result => {
                fetchBadges(dispatch, regionId);
                return {
                    data: {id: params.id}
                };
            });
        }
        default: {

        }
    }
};
