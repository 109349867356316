import gql from 'graphql-tag';
import {GET_LIST, GET_ONE, UPDATE, CREATE, GET_MANY} from 'react-admin';
import {listOptionsFromParams} from "./listOptionsFromParams";

const GET_ALL_REGIONS = ({page, perPage, sortField, ascending}) => gql`
    query {
        regionsList(listOptions: {pagination: {page: ${page}, perPage: ${perPage}}, sort: {field: "${sortField}", ascending: ${ascending}}}) {
            regions {
                id
                createdAt
                updatedAt
                name
                email
                slug
                latitude
                longitude
                timeZone
                dayOffset
                suspendedUntil
            }
            count
        }
    }
`;
const GET_REGION = (id) => gql`
    query {
        region(id: "${id}") {
            id
            createdAt
            updatedAt
            name
            email
            slug
            latitude
            longitude
            timeZone
            dayOffset
            suspendedUntil
        }
    }
`;
const CHANGE_REGION = gql`
    mutation ChangeRegion($id: ID!, $name: String, $email: String, $slug: String,
    $latitude: Float, $longitude: Float, $timeZone: String, $dayOffset: Float,
    $suspendedUntil: Date) {
        changeRegion(id: $id, name: $name, email: $email, slug: $slug, latitude: $latitude,
            longitude: $longitude, timeZone: $timeZone, dayOffset: $dayOffset, suspendedUntil: $suspendedUntil) {
            id
            name
            email
            slug
            latitude
            longitude
            timeZone
            dayOffset
            suspendedUntil
        }
    }
`;
const ADD_REGION = gql`
    mutation AddRegion($id: ID, $name: String!, $email: String!, $slug: String!,
    $latitude: Float!, $longitude: Float!, $timeZone: String!, $dayOffset: Float,
    $suspendedUntil: Date) {
        addRegion(id: $id, name: $name, email: $email, slug: $slug, latitude: $latitude,
            longitude: $longitude, timeZone: $timeZone, dayOffset: $dayOffset, suspendedUntil: $suspendedUntil) {
            id
            name
            email
            slug
            latitude
            longitude
            timeZone
            dayOffset
            suspendedUntil
        }
    }
`;


export const regionProvider = function(type, params, client) {
    switch(type) {
        case GET_LIST: {
            return client.query({
                query: GET_ALL_REGIONS(listOptionsFromParams(params))
            }).then(result => {
                return {
                    data: result.data.regionsList.regions,
                    total: result.data.regionsList.count
                };
            });
        }
        case GET_ONE: {
            return client.query({
                query: GET_REGION(params.id)
            }).then(result => {
                return {
                    data: result.data.region
                };
            });
        }
        case GET_MANY: {
            // used for user -> region selection
            return client.query({
                query: GET_REGION(params.ids[0])
            }).then(result => {
                return {
                    data: [result.data.region]
                };
            });
        }
        case UPDATE: {
            return client.mutate({
                variables: {
                    ...params.data
                },
                mutation: CHANGE_REGION
            }).then(result => {
                return {
                    data: result.data.changeRegion
                }
            });
        }
        case CREATE: {
            return client.mutate({
                variables: {
                    ...params.data
                },
                mutation: ADD_REGION
            }).then(result => {
                return {
                    data: result.data.addRegion
                }
            });
        }
        default: {

        }
    }
};