import {CREATE, DELETE, GET_LIST, GET_ONE, GET_MANY, UPDATE} from "react-admin";
import gql from 'graphql-tag';
import {listOptionsFromParams} from "./listOptionsFromParams";
import {fetchBadges} from "./badges";

const GET_GENRES_BY_REGION = (regionId, {page, perPage, sortField, ascending}, search, ignoreUnknown) => {
    return gql`
        query {
            region(id: "${regionId}") {
                genresList(listOptions: {pagination: {page: ${page}, perPage: ${perPage}}, sort: {field: "${sortField}", ascending: ${ascending}}}, search: "${search}", ignoreUnknown: ${ignoreUnknown === true}) {
                    genres {
                        id
                        name
                        isReviewPending
                        categoryId
                        category {
                            id
                            name
                        }
                    }
                    count
                }
            }
        }
    `;
};

const GET_GENRE = (id) => {
    return gql`
        query {
            genre(id: "${id}") {
                id
                name
                isReviewPending
                categoryId
                category {
                    id
                    name
                }
            }
        }
    `
};
const GET_GENRES = (ids) => {
    return gql`
        query {
            genres(ids: [${ids.map(e => JSON.stringify(e))}]) {
                id
                name
                isReviewPending
                categoryId
                category {
                    id
                    name
                }
            }
        }
    `
};

const CHANGE_GENRE = gql`
    mutation ChangeGenre($id: ID!, $name: String, $genreCategoryId: ID!) {
        changeGenre(id: $id, name: $name, genreCategoryId: $genreCategoryId) {
            id
            name
            isReviewPending
            categoryId
            category {
                id
                name
            }
        }
    }
`;

const ADD_GENRE = gql`
    mutation AddGenre($name: String!, $genreCategoryId: ID!) {
        addGenre(name: $name, genreCategoryId: $genreCategoryId) {
            id
            name
            isReviewPending
            categoryId
            category {
                id
                name
            }
        }
    }
`;

const DELETE_GENRE = gql`
    mutation DeleteGenre($genreId: ID!) {
        deleteGenre(id: $genreId)
    }
`;

export const genreProvider = function(type, params, client, regionId, {dispatch}) {
    switch(type) {
        case GET_LIST: {
            const search = params.filter.q ? '%' + params.filter.q + '%' : "";
            return client.query({
                query: GET_GENRES_BY_REGION(regionId, listOptionsFromParams(params), search, params.filter.ignoreUnknown)
            }).then(result => {
                return {
                    data: result.data.region.genresList.genres,
                    total: result.data.region.genresList.count
                };
            });
        }
        case GET_ONE: {
            return client.query({
                query: GET_GENRE(params.id)
            }).then(result => {
                return {
                    data: result.data.genre
                };
            });
        }
        case GET_MANY: {
            return client.query({
                query: GET_GENRES(params.ids)
            }).then(result => {
                return {
                    data: result.data.genres
                };
            });
        }
        case UPDATE: {
            return client.mutate({
                variables: {
                    ...params.data,
                    genreCategoryId: params.data.categoryId
                },
                mutation: CHANGE_GENRE
            }).then(result => {
                fetchBadges(dispatch, regionId);
                return {
                    data: result.data.changeGenre
                }
            });
        }
        case CREATE: {
            return client.mutate({
                variables: {
                    genreCategoryId: params.data.categoryId,
                    ...params.data
                },
                mutation: ADD_GENRE
            }).then(result => {
                return {
                    data: result.data.addGenre
                }
            });
        }
        case DELETE: {
            return client.mutate({
                variables: {
                    genreId: params.id
                },
                mutation: DELETE_GENRE
            }).then(() => {
                fetchBadges(dispatch, regionId);
                return {
                    data: {id: params.id}
                };
            });
        }
        default: {

        }
    }
};