import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import {EventListItem} from "./EventListItem";

const styles = {

};

const RawEventList = ({ events, classes, eventResource, context, selectedRegionSlug, withVenue }) => {
    return <List>
        {events.map(event => {
            return <EventListItem key={event.id} event={event} eventResource={eventResource} context={context}
                                  selectedRegionSlug={selectedRegionSlug} withVenue={withVenue} />
        })}
    </List>
};

export const EventList = withStyles(styles)(RawEventList);