import React from "react";
import {List, Datagrid, TextField, required, SimpleForm, TextInput} from 'react-admin';
import {ColorField} from "./fields/ColorField";
import {Edit, Create} from './forms';

import { ColorInput } from 'react-admin-color-input';
import {ListPagination} from "./pagination";

export const GenreCategoriesList = props => (
    <List {...props} bulkActionButtons={false} perPage={50} sort={{ field: 'name', order: 'ASC' }} pagination={<ListPagination/>}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <ColorField source="color" />
        </Datagrid>
    </List>
);

const genreCategoryForm = <SimpleForm redirect={"list"}>
    <TextInput source="name" validate={[required()]}/>
    <ColorInput source="color" picker="Photoshop" format={v => v ? '#' + v : undefined} parse={v => v ? v.replace('#', '') : undefined} />
</SimpleForm>

export const GenreCategoryEdit = (props) => {
    return <Edit undoable={false} {...props}>
        {genreCategoryForm}
    </Edit>;
}

export const GenreCategoryCreate = props => (
    <Create {...props} undoable={false}>
        {genreCategoryForm}
    </Create>
);