import React from "react";
import { connect } from 'react-redux';
import {List, Datagrid, TextField, required, SimpleForm, TextInput, Filter, ReferenceInput, SelectInput} from 'react-admin';
import {Edit, Create} from './forms';
import BooleanIsFalseField from "./fields/BooleanIsFalseField";
import {isReviewPendingStyle} from "./styles";
import {ListPagination} from "./pagination";

const GenresFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);


const genreRowStyle = (record) => {
    if (record.isReviewPending) {
        return {
            ...isReviewPendingStyle
        };
    }
    return {};
}

export const GenresList = props => (
    <List {...props} bulkActionButtons={false} filters={<GenresFilter/>} perPage={50} sort={{ field: 'isReviewPending', order: 'DESC' }} pagination={<ListPagination/>}>
        <Datagrid rowClick="edit" rowStyle={genreRowStyle}>
            <TextField source="name" />
            <TextField label="Category" source="category.name" />
            <BooleanIsFalseField source="isReviewPending" label={"reviewed"} />
        </Datagrid>
    </List>
);

const GenreForm = (props) => {
    const { selectedRegionSlug, ...rest } = props;
    const categoryReference = "r/" + selectedRegionSlug + "/genreCategories";

    return <SimpleForm {...rest}  redirect={"list"}>
        <TextInput source="name" validate={[required()]}/>
        <ReferenceInput label="Category" source="categoryId" reference={categoryReference} validate={[required()]}>
            <SelectInput optionText="name"/>
        </ReferenceInput>
    </SimpleForm>
}

const genreFormOptions = (state, ownProps) => {
    const selectedRegionSlug = state.selectedRegionSlug;
    return {
        ...ownProps,
        selectedRegionSlug: selectedRegionSlug
    }
}

export const RawGenreEdit = (props) => {
    const {selectedRegionSlug, ...rest} = props;

    return <Edit {...rest} undoable={false}>
        <GenreForm selectedRegionSlug={selectedRegionSlug} />
    </Edit>
};
export const RawGenreCreate = (props) => {
    const {selectedRegionSlug, ...rest} = props;

    return <Create {...rest} undoable={false}>
        <GenreForm selectedRegionSlug={selectedRegionSlug} />
    </Create>
};
export const GenreCreate = connect(
    (state, ownProps) => {
        return genreFormOptions(state, ownProps);
    })(RawGenreCreate);
export const GenreEdit = connect(
    (state, ownProps) => {
        return genreFormOptions(state, ownProps);
    })(RawGenreEdit);