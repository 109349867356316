export default (previousState = null, { type, regionSlug }) => {
    if (type === 'SELECTED_REGION_SLUG') {
        if (regionSlug) {
            localStorage.setItem("selectedRegionSlug", regionSlug);
        } else {
            localStorage.removeItem("selectedRegionSlug");
        }
        return regionSlug;
    }
    return previousState;
}