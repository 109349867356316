import React, {Fragment} from "react";
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    required,
    LongTextInput,
    SimpleForm,
    BooleanInput,
    ReferenceInput,
    AutocompleteInput,
    ArrayInput,
    SimpleFormIterator,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    FormDataConsumer,
    Toolbar,
    SaveButton,
    CardActions,
    CheckboxGroupInput,
    UPDATE
} from 'react-admin';
import {DateTimeInput} from "react-admin-date-inputs";
import {Filter, TextInput} from 'react-admin';
import {isURL} from "./validation/isURL";
import BooleanIsFalseField from "./fields/BooleanIsFalseField";
import LinkIcon from '@material-ui/icons/Link';
import Chip from '@material-ui/core/Chip';
import {Edit, Create} from './forms';
import {isPromotedStyle, isReviewPendingStyle} from "./styles";
import {ListPagination} from "./pagination";
import dataProvider from "../dataProvider/denkmalDataProvider";

const eventRowStyle = (record, index) => {
    const styles = {
        opacity: record.isHidden ? "0.5" : "1",
    };
    if (record.isReviewPending) {
        Object.assign(styles, isReviewPendingStyle);
    } else if (record.isPromoted) {
        Object.assign(styles, isPromotedStyle);
    }
    return styles;
}

const EventsFilters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn/>
    </Filter>
);

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const ListBulkActions = props => {
    return <Fragment>
        <Button variant="contained" color="primary" onClick={(e) => {
            const selectedIds = props.selectedIds;
            const promises = selectedIds.map(id => {
                return dataProvider(UPDATE, props.resource, {
                    id: id,
                    data: {id: id, isHidden: true}
                }, {refresh: true, onSuccess: { refresh: true }});
            });
            Promise.all(promises).then(done => {
                window.location.reload();
            }).catch(e => {});
        }}>Hide</Button>
    </Fragment>
};

export const EventsList = props => {
    return <List {...props}
          filters={<EventsFilters/>}
          sort={{field: 'isReviewPending', order: 'DESC'}}
          pagination={<ListPagination/>}
          bulkActionButtons={<ListBulkActions/>}
    >
        <Datagrid rowClick="edit" rowStyle={eventRowStyle}>
            <DateField source="from"
                       options={{year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit'}}
                       locales="de-CH"/>
            <TextField source="description"/>
            <TextField source="venue.name" sortable={true} label={"Venue"}/>
            <BooleanIsFalseField source="isReviewPending" label={"reviewed"}/>
        </Datagrid>
    </List>
};

const EditTitle = ({record}) => {
    return <span>{record ? record.description : ''}</span>;
};



class VenueLink extends React.Component {
    render() {
        const selectedVenueId = this.props.input.value;
        const selectedVenue = this.props.choices.find(c => c.id === selectedVenueId);
        if (!selectedVenue) {
            return null;
        }

        return <a target="_blank" rel="noopener noreferrer" href={selectedVenue.url}>{selectedVenue.url}</a>
    }
}

const SourceType = ({record}) => {
    if (record.sourceUrl) {
        return <Chip
            label={record.sourceType}
            component="a"
            href={record.sourceUrl}
            clickable
            variant="outlined primary"
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
        />;
    }
    if (!record.sourceType) {
        return null;
    }
    return <Chip
        label={record.sourceType}
        component="div"
        variant="outlined primary"
        color="primary"
    />;
}

const dynamicRedirect = (basePath, id, data, selectedRegionSlug) => {
    const regex = /[?&]context=([^&]*)/gi;
    const found = regex.exec(window.location.href);
    if (found && found.length >= 2) {
        return decodeURI(found[1]);
    }

    return `/r/${selectedRegionSlug}/events`; // list doesn't work here as it fetches strange things
};

const EventForm = (props) => {
    if (props.record.isReviewPending) {
        // automatically unhide pending events
        props.record.isHidden = false;
    }

    const {selectedRegionSlug, ...rest} = props;
    const venuesReference = "r/" + selectedRegionSlug + "/venues";
    const genresReference = "r/" + selectedRegionSlug + "/genres";

    const redirect = (basePath, id, data) => {
        return dynamicRedirect(basePath, id, data, selectedRegionSlug);
    };

    return <SimpleForm {...rest} redirect={redirect} toolbar={<EditToolbar/>}>
        <SourceType record={props.record}/>
        <LongTextInput source="description" validate={[required()]} fullWidth={true}/>
        <ReferenceArrayInput label="Genres" source="genreIds" reference={genresReference} perPage={20} filter={{ignoreUnknown: true}}>
            <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>
        <DateTimeInput label="From" source="from" validate={[required()]}
                       options={{format: 'dd.MM.YYYY, HH:mm', ampm: false, showTodayButton: true}}/>
        <DateTimeInput label="Until" source="until"
                       options={{format: 'dd.MM.YYYY, HH:mm', ampm: false, clearable: true}}/>
        <BooleanInput label="Display time" source="hasTime" defaultValue={true}/>

        <ReferenceInput label="Venue" source="venueId" reference={venuesReference} validate={[required()]}>
            <AutocompleteInput optionText="name"
                               translateChoice={false}
                               limitChoicesToValue={true}
            />
        </ReferenceInput>

        <FormDataConsumer>
            {({formData}) => {
                return <ReferenceInput key={formData.venueId} label="Venue" source="venueId" reference={venuesReference}
                                       validate={[required()]}>
                    <VenueLink optionText="url"/>
                </ReferenceInput>;
            }}
        </FormDataConsumer>
        <BooleanInput label="Hidden" source="isHidden"/>
        <BooleanInput label="Promoted" source="isPromoted"/>
        <CheckboxGroupInput source="tags" choices={[
            { id: 'lauter', name: 'lauter' },
        ]} />

        <ArrayInput source="links">
            <SimpleFormIterator>
                <TextInput source="label" validate={[required()]}/>
                <TextInput source="url" type={"url"} validate={[required(), isURL]}/>

                <FormDataConsumer>
                    {({scopedFormData, getSource}) => {
                        if (!scopedFormData) {
                            return;
                        }
                        getSource('url'); // suppressed warnings
                        const url = scopedFormData.url;
                        if (!url || isURL(url)) {
                            return null;
                        }
                        return <a target="_blank" rel="noopener noreferrer" href={url}><LinkIcon/></a>;
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    </SimpleForm>
};

const eventFormOptions = (state, ownProps) => {
    const selectedRegionSlug = state.selectedRegionSlug;
    return {
        ...ownProps,
        selectedRegionSlug: selectedRegionSlug
    }
}

const EventEditActions = (props) => {
    return <CardActions>
        <TopSaveButton />
        {props.data && <Button color="primary" onClick={() => {
            const eventsResource = "r/" + props.selectedRegionSlug + "/events";

            dataProvider(UPDATE, eventsResource, {
                id: props.data.id,
                data: {id: props.data.id, isHidden: !props.data.isHidden}
                }, {
                refresh: true
            }).then(() => { window.history.back() }).catch(() => {});
        }}>{props.data.isHidden ? 'Unhide' : 'Hide'}</Button>}
    </CardActions>
};

const TopSaveButton = props => <SaveButton onClick={() => {
    const submit = document.querySelector( ".simple-form button[type=submit]" );
    submit.click();
}} />;

const RawEventEdit = props => {
    const {dispatch, selectedRegionSlug, ...rest} = props;
    return <Edit {...rest} title={<EditTitle/>} actions={<EventEditActions {...props} />} undoable={false}>
        <EventForm selectedRegionSlug={selectedRegionSlug}/>
    </Edit>
}

export const EventEdit = connect(
    (state, ownProps) => {
        return eventFormOptions(state, ownProps);
    })(RawEventEdit);


export const RawEventCreate = (props) => {
    const {dispatch, selectedRegionSlug, regions, ...rest} = props;

    return <Create {...rest} title={<EditTitle/>} undoable={false}>
        <EventForm selectedRegionSlug={selectedRegionSlug}/>
    </Create>
};
export const EventCreate = connect(
    (state, ownProps) => {
        return eventFormOptions(state, ownProps);
    })(RawEventCreate);
