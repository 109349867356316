import gql from 'graphql-tag';
import {listOptionsFromParams} from "./listOptionsFromParams";
import {GET_ONE, CREATE, UPDATE, GET_LIST} from "react-admin";
import {fetchBadges} from "./badges";


const GET_EVENTS_BY_REGION = (regionId, {page, perPage, sortField, ascending}, search) => {
    return gql`
        query {
            region(id: "${regionId}") {
                eventsList(listOptions: {pagination: {page: ${page}, perPage: ${perPage}}, sort: {field: "${sortField}", ascending: ${ascending}}}, search: "${search}") {
                    events {
                        id
                        description
                        from
                        until
                        hasTime
                        genres {
                            id
                            name
                        }
                        tags
                        venueId
                        links {
                            label
                            url
                        }
                        venue {
                            id
                            name
                            url
                        }
                        isHidden
                        isPromoted
                        isReviewPending
                        sourceType
                        sourceIdentifier
                        sourceUrl
                    }
                    count
                }
            }
        }
    `;
}
const GET_EVENT = (id) => gql`
    query {
        event(id: "${id}") {
            id
            description
            from
            until
            hasTime
            genres {
                id
                name
            }
            tags
            venueId
            links {
                label
                url
            }
            venue {
                id
                name
                url
            }
            isHidden
            isPromoted
            isReviewPending
            sourceType
            sourceIdentifier
            sourceUrl
        }
    }
`;

const REVIEW_EVENT = gql`
    mutation ReviewEvent($id: ID!, $description: String, $from: Date, $until: Date, $isHidden: Boolean, $isPromoted: Boolean, $venueId: ID, $hasTime: Boolean, $links: [EventLinkInput], $genres: [ID], $tags: [String]) {
        reviewEvent(id: $id, description: $description, from: $from, until: $until, isHidden: $isHidden, isPromoted: $isPromoted, venueId: $venueId, hasTime: $hasTime, links: $links, genres: $genres, tags: $tags) {
            id
            description
            from
            until
            hasTime
            genres {
                id
                name
            }
            tags
            venueId
            links {
                label
                url
            }
            venue {
                id
                name
                url
            }
            isHidden
            isPromoted
            isReviewPending
            sourceType
            sourceIdentifier
            sourceUrl
        }
    }
`;
const ADD_EVENT = gql`
    mutation AddEvent($id: ID, $description: String!, $from: Date!, $until: Date, $isHidden: Boolean, $isPromoted: Boolean, $venueId: ID!, $links: [EventLinkInput], $genres: [ID], $tags: [String]) {
        addEvent(id: $id, description: $description, from: $from, until: $until, isHidden: $isHidden, isPromoted: $isPromoted, venueId: $venueId, links: $links, genres: $genres, tags: $tags) {
            id
            description
            from
            until
            hasTime
            genres {
                id
                name
            }
            tags
            venueId
            links {
                label
                url
            }
            venue {
                id
                name
                url
            }
            isHidden
            isPromoted
            isReviewPending
            sourceType
            sourceIdentifier
            sourceUrl
        }
    }
`;

const transformEventResponse = (event) => {
    if (event.genres && event.genres.length >= 1) {
        event.genreIds = event.genres.map(g => g.id);
    }
    return event;
}

const transformData = data => {
    const transformed = {
        ...data
    };
    if (data.description) {
        transformed.description = data.description.trim();
    }
    if (data.genreIds) {
        transformed.genres = data.genreIds;
    }
    return transformed;
}

export const eventProvider = function(type, params, client, regionId, {dispatch}) {
    switch(type) {
        case GET_LIST: {
            const search = params.filter.q ? '%' + params.filter.q + '%' : "";
            return client.query({
                query: GET_EVENTS_BY_REGION(regionId, listOptionsFromParams(params), search)
            }).then(result => {
                return {
                    data: result.data.region.eventsList.events,
                    total: result.data.region.eventsList.count
                };
            });
        }
        case GET_ONE: {
            return client.query({
                query: GET_EVENT(params.id)
            }).then(result => {
                return {
                    data: transformEventResponse(result.data.event)
                };
            });
        }
        case UPDATE: {
            return client.mutate({
                variables: transformData(params.data),
                mutation: REVIEW_EVENT
            }).then(result => {
                fetchBadges(dispatch, regionId);
                return {
                    data: result.data.reviewEvent
                }
            });
        }
        case CREATE: {
            return client.mutate({
                variables: transformData(params.data),
                mutation: ADD_EVENT
            }).then(result => {
                return {
                    data: result.data.addEvent
                }
            });
        }
        default: {

        }
    }
};