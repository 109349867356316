import gql from 'graphql-tag';
import {GET_LIST, GET_ONE, UPDATE, CREATE, DELETE} from 'react-admin';
import {listOptionsFromParams} from "./listOptionsFromParams";

const GET_ALL_USERS = ({page, perPage, sortField, ascending}) => gql`
    query {
        usersList(listOptions: {pagination: {page: ${page}, perPage: ${perPage}}, sort: {field: "${sortField}", ascending: ${ascending}}}) {
            users {
                id
                email
                name
                accessLevel
                loginKey
                region { name id }
                regionId
            }
            count
        }
    }
`;
const GET_USER = (id) => gql`
    query {
        user(id: "${id}") {
            id
            email
            name
            accessLevel
            loginKey
            region { name id }
            regionId
        }
    }
`;
const CHANGE_USER = gql`
    mutation ChangeUser($id: ID!, $name: String, $email: String, $regionId: ID, $password: Password, $loginKey: String, $accessLevel: AccessLevel) {
        changeUser(id: $id, name: $name, email: $email, regionId: $regionId, password: $password, loginKey: $loginKey, accessLevel: $accessLevel) {
            id
            email
            name
            accessLevel
            loginKey
            region { name id }
            regionId
        }
    }
`;
const ADD_USER = gql`
    mutation AddUser($id: ID, $name: String!, $email: String!, $regionId: ID, $password: Password!, $loginKey: String, $accessLevel: AccessLevel) {
        addUser(id: $id, name: $name, email: $email, regionId: $regionId, password: $password, loginKey: $loginKey, accessLevel: $accessLevel) {
            id
            email
            name
            accessLevel
            loginKey
            region { name id }
            regionId
        }
    }
`;

const DELETE_USER = gql`
    mutation DeleteUser($id: ID!) {
        deleteUser(id: $id)
    }
`;


export const userProvider = function(type, params, client) {
    switch(type) {
        case GET_LIST: {
            return client.query({
                query: GET_ALL_USERS(listOptionsFromParams(params))
            }).then(result => {
                return {
                    data: result.data.usersList.users,
                    total: result.data.usersList.count
                };
            });
        }
        case GET_ONE: {
            return client.query({
                query: GET_USER(params.id)
            }).then(result => {
                return {
                    data: result.data.user
                };
            });
        }
        case UPDATE: {
            let variables = {
                ...params.data
            };
            delete(variables.region);
            return client.mutate({
                variables: variables,
                mutation: CHANGE_USER
            }).then(result => {
                return {
                    data: result.data.changeUser
                }
            });
        }
        case CREATE: {
            const variables = {
                ...params.data
            };

            return client.mutate({
                variables: variables,
                mutation: ADD_USER
            }).then(result => {
                return {
                    data: result.data.addUser
                }
            });
        }
        case DELETE: {
            return client.mutate({
                variables: {
                    id: params.id
                },
                mutation: DELETE_USER
            }).then(() => {
                return {
                    data: {id: params.id}
                };
            });
        }
        default: {

        }
    }
};