import React from 'react';
import { Layout } from 'react-admin';
import {DenkmalMenu} from "./DenkmalMenu";
import {theme} from "./theme";


export class DenkmalLayout extends React.Component {
    render() {
        return <Layout
            {...this.props}
            menu={DenkmalMenu}
            theme={theme}
        />
    }
}