import React from "react";
import {Edit as RAEdit} from 'react-admin';
import {Create as RACreate} from 'react-admin';

const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
        const dialogs = document.body.querySelector("[role~=dialog]");
        if (!dialogs) {
            window.history.back();
            return true;
        }
    }
}


export class Edit extends React.Component {
    componentDidMount() {
        document.addEventListener("keydown", handleKeyDown);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", handleKeyDown);
    }
    render() {
        return <RAEdit {...this.props}>
            {this.props.children}
        </RAEdit>
    }
}

export class Create extends React.Component {
    componentDidMount() {
        document.addEventListener("keydown", handleKeyDown);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", handleKeyDown);
    }
    render() {
        return <RACreate {...this.props}>
            {this.props.children}
        </RACreate>
    }
}