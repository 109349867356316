import React from 'react';
import { Provider } from 'react-redux';
import dataProvider from "./dataProvider/denkmalDataProvider";
import defaultMessages from 'ra-language-english';
import selectedRegionSlugReducer from "./reducers/selectedRegionSlug";
import regionsReducer from "./reducers/regions";
import badgesReducer from "./reducers/badges";
import {DenkmalAdmin} from "./Admin";
import {authProvider} from './authentication/authProvider';
import {regionSlugForPath} from "./helpers/regionRouteParser";


// side effects
const i18nProvider = locale => {
    return defaultMessages;
};
const history = require("history").createBrowserHistory();

export let store = null;

class App extends React.Component {

    render() {
        let regionSlug = regionSlugForPath(window.location.pathname.substring(1));
        if (regionSlug) {
            localStorage.setItem("selectedRegionSlug", regionSlug);
        } else {
            regionSlug = localStorage.getItem("selectedRegionSlug");
        }

        store = require('ra-core').createAdminStore({
            authProvider,
            dataProvider,
            i18nProvider,
            history,
            customReducers: {regions: regionsReducer, selectedRegionSlug: selectedRegionSlugReducer, badges: badgesReducer},
            initialState:{regions: null, selectedRegionSlug: regionSlug}
        });

        return (
            <Provider store={store}>
                <DenkmalAdmin history={history} dataProvider={dataProvider} authProvider={authProvider} />
            </Provider>
        );
    }
}

export default App;