import {createApiClient} from "../dataProvider/apiClient";
import gql from 'graphql-tag';

const LOGIN = (email, password) => gql`
    query {
        authenticate(email: "${email}", password: "${password}") {
            user {
                id
                name
                region {id}
            },
            token
        }
    }
`;

export const loginRequest = function(username, password) {
    return createApiClient().query({
        query: LOGIN(username, password)
    }).then(result => {
        return {
            token: result.data.authenticate.token
        };
    });
};