import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, GET_LIST, AUTH_GET_PERMISSIONS } from 'react-admin';
import {loginRequest} from "./loginRequest";
import dataProvider from "../dataProvider/denkmalDataProvider";
import {store} from "../App";
import {getRegionsFromState} from "../helpers/getRegionsFromState";
import jwt_decode from 'jwt-decode';

const clearLocalStorage = function() {
    [
        'token',
        'selectedRegionSlug'
    ].forEach(item => localStorage.removeItem(item));
};


const loadRegionsIntoStore = () => dataProvider(GET_LIST, 'region', {}).then(({data}) => {
        store.dispatch({type: 'SET_REGIONS', regions: data});
        return true;
    });

export const authProvider = (type, params) => {
    // called when the user attempts to log in
    if (type === AUTH_LOGIN) {
        clearLocalStorage();
        const { username, password } = params;
        return loginRequest(username,password).then(({token}) => {
            localStorage.setItem('token', token);
            const decodedToken = jwt_decode(token);
            const selectedRegionSlug = decodedToken.regionSlug;

            if (selectedRegionSlug) {
                store.dispatch({type: 'SELECTED_REGION_SLUG', regionSlug: selectedRegionSlug});
            }
        }).then(t => loadRegionsIntoStore());
    }
    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        clearLocalStorage();
        return Promise.resolve();
    }
    // called when the API returns an error
    if (type === AUTH_ERROR) {
        if (params.graphQLErrors) {
            if (params.graphQLErrors.find(e =>
                e.extensions && e.extensions.code === 'FORBIDDEN')) {
                clearLocalStorage();
                return Promise.reject();
            }
        }
        return Promise.resolve();
    }
    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
        if (!localStorage.getItem('token')) {
            return Promise.reject();
        }

        let regions = getRegionsFromState(store.getState());

        if (regions && regions.length > 0) {
            return Promise.resolve();
        }


        return loadRegionsIntoStore();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }

    return Promise.reject('Unknown method');
};