export const listOptionsFromParams = function(params, defaultSort = "name") {
    const listOptions = {};

    if (params.pagination) {
        Object.assign(listOptions, {
            ...params.pagination
        });
    } else {
        Object.assign(listOptions, {
            page: 1,
            perPage: 100000
        });
    }

    if (params.sort) {
        Object.assign(listOptions, {
            sortField: params.sort.field,
            ascending: params.sort.order === "ASC"
        });
    } else {
        Object.assign(listOptions, {
            sortField: defaultSort,
            ascending: true
        });
    }

    return listOptions;
}