import React from "react";
import {GET_LIST} from "react-admin";
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import { withDataProvider } from 'react-admin';
import moment from 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getRegionsFromState} from "../helpers/getRegionsFromState";
import {EventDays} from './EventDays';

class RawCurrentEventDays extends React.Component {
    componentDidMount() {
        this.fetchEventDays();
    }

    componentDidUpdate(prevProps) {
        // handle refresh
        if (this.props.version !== prevProps.version
            || this.props.selectedRegion !== prevProps.selectedRegion) {
            this.fetchEventDays();
        }
    }


    async fetchEventDays() {
        const region = this.props.selectedRegion;
        if (!region) {
            return;
        }

        const eventDayStrings = [];
        const today = moment.utc().subtract(region.dayOffset, 'hours').tz(region.timeZone);

        for(let i = 0; i < 10; i++) {
            eventDayStrings.push(today.clone().add(i, 'days').format("YYYY-MM-DD"));
        }

        await this.props.dataProvider(
            GET_LIST,
            `r/${this.props.selectedRegionSlug}/eventDays`,
            {
                eventDayStrings: eventDayStrings
            }
        );
    }

    render() {
        if (!this.props.eventDays || this.props.eventDays.length < 1) {
            return <CircularProgress />
        }
        return <Card>
            <EventDays eventDays={this.props.eventDays} selectedRegionSlug={this.props.selectedRegionSlug} />
        </Card>
    }

}

export const CurrentEventDays = withDataProvider(connect(
    (state, ownProps) => {
        let selectedRegion = null;
        const regions = getRegionsFromState(state);
        if (regions && regions.find && state.selectedRegionSlug) {
            selectedRegion = regions.find(r => r.slug === state.selectedRegionSlug);
        }


        let eventDays = [];
        if (state.selectedRegionSlug) {
            let eventDaysResource = `r/${state.selectedRegionSlug}/eventDays`;
            const data = state.admin.resources[eventDaysResource].data;
            eventDays = Object.values(data).filter(value => value.id != null);
        }

        return {
            ...ownProps,
            version: state.admin.ui.viewVersion,
            selectedRegionSlug: state.selectedRegionSlug,
            selectedRegion: selectedRegion,
            eventDays: eventDays,
        }
    },
    (dispatch, ownProps) => {
        return {

        }
    })(RawCurrentEventDays));