import React from 'react';
import { List, Datagrid, TextField, Toolbar,
    SaveButton, SimpleForm, TextInput,
    NumberInput, required} from 'react-admin';
import {DateTimeInput} from 'react-admin-date-inputs';
import {Edit, Create} from './forms';
import {ListPagination} from "./pagination";


export const RegionsList = props => (
    <List {...props} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} pagination={<ListPagination/>}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="slug" />
            <TextField source="timeZone" />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => {
    return <span>{record ? record.name : ''}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const regionForm = <SimpleForm toolbar={<EditToolbar />} redirect={"list"}>
        <TextInput source="name" validate={[required()]}/>
        <TextInput source="slug" validate={[required()]} />
        <TextInput source="timeZone" validate={[required()]} />
        <TextInput source="email" validate={[required()]} />
        <NumberInput source="latitude" validate={[required()]}/>
        <NumberInput source="longitude" validate={[required()]}/>
        <NumberInput source="dayOffset" />
        <DateTimeInput label="Suspended until" source="suspendedUntil"
                       options={{ format: 'dd.MM.YYYY, HH:mm', ampm: false, clearable: true }}  />
        </SimpleForm>

export const RegionEdit = props => {
    return <Edit {...props} title={<EditTitle />} undoable={false}>
            {regionForm}
        </Edit>;
}

export const RegionCreate = props => (
    <Create {...props} title={<EditTitle />} undoable={false}>
        {regionForm}
    </Create>
);