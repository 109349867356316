import React from 'react';
import { connect } from 'react-redux';
import inflection from 'inflection';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import DefaultIcon from '@material-ui/icons/ViewList';
import Badge from '@material-ui/core/Badge';
import {DenkmalRegionSelect} from "./RegionSelect";
import {fetchBadges} from "./dataProvider/badges";

import {DashboardMenuItem,MenuItemLink,Responsive,getResources,translate, withDataProvider} from 'react-admin';
import {getRegionsFromState} from "./helpers/getRegionsFromState";

const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
};

const translatedResourceName = (resource, translate) =>
    translate(`resources.${resource.name}.name`, {
        smart_count: 2,
        _:
            resource.options && resource.options.label
                ? translate(resource.options.label, {
                    smart_count: 2,
                    _: resource.options.label,
                })
                : inflection.humanize(inflection.pluralize(resource.name)),
    });
class Menu extends React.Component {
    async componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        // handle refresh
        if (this.props.version !== prevProps.version
            || this.props.selectedRegionSlug !== prevProps.selectedRegionSlug) {
            this.fetchData();
        }
    }
    async fetchData() {
        if (!this.props.selectedRegion) {
            return;
        }
        fetchBadges(this.props.dispatch, this.props.selectedRegion.id);
    }

    render() {
        let {
            classes,
            className,
            hasDashboard,
            onMenuClick,
            open,
            resources,
            translate,
            logout,
            badgesPerResource
        } = this.props;


        return <div className={classnames(classes.main, className)}>
            {open ? <DenkmalRegionSelect/> : null}
            {hasDashboard && <DashboardMenuItem onClick={onMenuClick}/>}
            {resources
                .filter(r => r.hasList)
                .map(resource => {
                    let icon = resource.icon ? <resource.icon/> : <DefaultIcon/>;
                    if (badgesPerResource && badgesPerResource[resource.name]) {
                        icon = <Badge badgeContent={badgesPerResource[resource.name]} color="primary">
                            {icon}
                        </Badge>;
                    }

                    return <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={translatedResourceName(resource, translate)}
                        leftIcon={
                            icon
                        }
                        onClick={onMenuClick}
                    />;
                })}
            <Responsive xsmall={logout} medium={null}/>
        </div>;
    }
}


const mapStateToProps = state => {
    let selectedRegion = null;
    const regions = getRegionsFromState(state);
    let badgesPerResource = null;
    let selectedRegionSlug = null;
    if (regions && regions.find && state.selectedRegionSlug) {
        selectedRegion = regions.find(r => r.slug === state.selectedRegionSlug);
        selectedRegionSlug = selectedRegion.slug;
        const badgesCount = state.badges[selectedRegion.id];

        if (badgesCount) {
            badgesPerResource = {};
            badgesPerResource[`r/${selectedRegionSlug}/events`] = badgesCount.pendingEventsCount;
            badgesPerResource[`r/${selectedRegionSlug}/venues`] = badgesCount.pendingVenuesCount;
            badgesPerResource[`r/${selectedRegionSlug}/genres`] = badgesCount.pendingGenresCount;
        }
    }

    return {
        version: state.admin.ui.viewVersion,
        selectedRegion: selectedRegion,
        selectedRegionSlug: selectedRegionSlug,
        badgesPerResource: badgesPerResource,
        open: state.admin.ui.sidebarOpen,
        resources: getResources(state),
        pathname: state.router.location.pathname, // used to force redraw on navigation
    };
};

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {},
        null,
        {
            areStatePropsEqual: (prev, next) =>
                prev.resources.every(
                    (value, index) => value === next.resources[index] // shallow compare resources
                ) &&
                prev.pathname === next.pathname &&
                prev.open === next.open,
        }
    ),
    withStyles(styles),
    withDataProvider
);

export const DenkmalMenu = enhance(Menu);