import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {EventList} from "./EventList";

const styles = {

};

const RawEventDay = ({ events, eventDay, eventResource, classes, context, selectedRegionSlug }) => {
        const pendingEvents = events.filter(e => e.isReviewPending === true);
        const reviewedEvents = events.filter(e => e.isReviewPending === false);

        return <>
            <h1>{eventDay.format('D.M.YYYY')}</h1>
            {pendingEvents.length > 0 ? <><h2>Pending events</h2>
            <EventList events={pendingEvents} eventResource={eventResource} context={context}
                       selectedRegionSlug={selectedRegionSlug} withVenue={true} /></> : null}
            {reviewedEvents.length > 0 ? <><h2>Reviewed events</h2>
                <EventList events={reviewedEvents} eventResource={eventResource} context={context}
                           selectedRegionSlug={selectedRegionSlug} withVenue={true} /></> : null}
        </>
};

export const EventDay = withStyles(styles)(RawEventDay);