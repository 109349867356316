import ApolloClient from "apollo-boost";
import { InMemoryCache } from 'apollo-cache-inmemory';


export const createApiClient = function() {
    let options = {
        uri: process.env.REACT_APP_API,
        headers: {},
        cache: new InMemoryCache({
            addTypename: false // we need to disable the __typename fields as it breaks mutations with input types
        })
    };

    const token = localStorage.getItem("token");
    if (token) {
        options.headers = {
            ...options.headers,
            authentication: `Bearer ${token}`
        };
    }


    const apiClient = new ApolloClient(options);
    apiClient.defaultOptions = {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache'
        }
    };
    return apiClient;
};