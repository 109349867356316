import React from "react";
import { connect } from 'react-redux';
import {
    List,
    Datagrid,
    TextField,
    ArrayField,
    TextInput,
    NumberInput,
    BooleanInput,
    SimpleForm,
    required,
    SaveButton,
    Filter,
    Toolbar,
    ArrayInput,
    SimpleFormIterator,
    number
} from 'react-admin';
import BooleanIsFalseField from "./fields/BooleanIsFalseField";
import {isURL} from "./validation/isURL";
import {Edit, Create} from './forms';
import {isReviewPendingStyle} from "./styles";
import {VenueMergeButton} from "./venueMergeButton";
import {ListPagination} from "./pagination";
import {EventList} from "../dashboard/EventList";

const venueRowStyle = (record, index) => {
    const styles = {
        opacity: record.isSuspended ? 0.2 : 1
    };

    if (record.isReviewPending) {
        Object.assign(styles, isReviewPendingStyle);
    }
    return styles;
}

const VenuesFilters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);


export const VenuesList = props => (
    <List {...props} bulkActionButtons={false} filters={<VenuesFilters/>} sort={{ field: 'isReviewPending', order: 'DESC' }} pagination={<ListPagination/>}>
        <Datagrid rowClick="edit" rowStyle={venueRowStyle}>
            <TextField source="name" />
            <ArrayField source="aliases" sortable={false}>
                <Datagrid>
                    <TextField source="name" />
                </Datagrid>
            </ArrayField>
            <BooleanIsFalseField source="isReviewPending" label={"reviewed"} />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => {
    return <span>{record ? record.name : ''}</span>;
};

const EditToolbar = props => {
    return <Toolbar {...props} style={{justifyContent: 'space-between'}}>
        <SaveButton />
        <VenueMergeButton resource={props.resource} venue={props.record} />
    </Toolbar>
};


const venueForm = ( create) => <SimpleForm toolbar={create ? <Toolbar><SaveButton/></Toolbar> : <EditToolbar />} >
        <TextInput source="name" validate={[required()]}/>
        <TextInput source="address" />
        <TextInput source="url" parse={v => v ? v : null} type={"url"} validate={[isURL]} resettable />
        <TextInput source="email" type={"email"}/>
        <NumberInput source="latitude" validate={[number()]} />
        <NumberInput source="longitude" validate={[number()]} />
        <BooleanInput label="Suspended" source="isSuspended" />
        <BooleanInput label="Ignore scraper" source="ignoreScraper" />
        <TextInput label="Facebook page ID" source="facebookPageId"/>
        <TextInput source="twitter"/>
        {create ? null : <ArrayInput source="aliases">
            <SimpleFormIterator>
                <TextInput source="name" validate={[required()]} />
            </SimpleFormIterator>
        </ArrayInput>}
    </SimpleForm>;


const RawAside = (props) => {
    if (!props.record || !props.record.events || props.record.events.length === 0) {
        return null;
    }

    return <div style={{margin: "10px", minWidth: "260px", maxWidth: "400px"}}>
        <h2>{props.record.name}</h2>
        <h3 style={{color: '#989898',marginBottom:0}}>upcoming</h3>
        <EventList events={props.record.events} selectedRegionSlug={props.selectedRegionSlug} context={window.location.pathname} withVenue={false} />
    </div>
};
const Aside = connect(
    (state, ownProps) => {
        return {
            ...ownProps,
            selectedRegionSlug: state.selectedRegionSlug
        }
    },
    (dispatch, ownProps) => {
        return {

        }
    })(RawAside);

export const VenueEdit = (props) => {
    return <Edit undoable={false} title={<EditTitle/>} {...props} aside={<Aside {...props} />}>
        {venueForm( false)}
        </Edit>;
}

export const VenueCreate = props => (
    <Create {...props} title={<EditTitle />}>
        {venueForm(true)}
    </Create>
);