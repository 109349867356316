import {regionProvider} from "./regions";
import {venueProvider} from "./venues";
import {store} from "../App";
import {getRegionsFromState} from "../helpers/getRegionsFromState";
import {eventProvider} from "./events";
import {createApiClient} from './apiClient';
import {GET_LIST} from "react-admin";
import {genreCategoryProvider} from "./genreCategories";
import {genreProvider} from "./genres";
import {eventDaysProvider} from "./eventDays";
import {userProvider} from "./users";



const dataProvider = (type, resource, params) => {
    const client = createApiClient();

    switch(resource) {
        case 'region': {
            return regionProvider(type, params, client);
        }
        case 'user': {
            return userProvider(type, params, client);
        }
        default: {}
    }

    if (store) {
        return new Promise((resolve) => {
            let regions = getRegionsFromState(store.getState());
            if (regions) {
                return resolve(regions);
            }
            dataProvider(GET_LIST, 'region', {}).then(({data}) => {
                return resolve(data);
            });
        }).then(regions => {
            const selectedRegionSlug = store.getState().selectedRegionSlug;

            let regionId = null;
            if (selectedRegionSlug) {
                const selectedRegion = regions.find(r => r.slug === selectedRegionSlug);
                if (selectedRegion) {
                    regionId = selectedRegion.id;
                }
            }

            if (regionId) {
                const splitResource = resource.split('/');
                if (splitResource.length > 2) {
                    let regionResource = splitResource.slice(2).join('/');

                    switch (regionResource) {
                        case 'venues': {
                            return venueProvider(type, params, client, regionId, store);
                        }
                        case 'events': {
                            return eventProvider(type, params, client, regionId, store);
                        }
                        case 'genreCategories': {
                            return genreCategoryProvider(type, params, client, regionId, store);
                        }
                        case 'genres': {
                            return genreProvider(type, params, client, regionId, store);
                        }
                        case 'eventDays': {
                            return eventDaysProvider(type, params, client, regionId, store);
                        }
                        default: {}
                    }
                }
            }
        });
    }

}

export default dataProvider;