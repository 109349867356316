import jwt_decode from "jwt-decode";

export const isAdminUser = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken) {
        return false;
    }
    const decodedToken = jwt_decode(userToken);

    return decodedToken.accessLevel === "Admin";
};