import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from "react-admin";
import gql from 'graphql-tag';
import {listOptionsFromParams} from "./listOptionsFromParams";

const GET_GENRE_CATEGORIES_BY_REGION = (regionId, {page, perPage, sortField, ascending}) => {
    return gql`
        query {
            region(id: "${regionId}") {
                genreCategoriesList(listOptions: {pagination: {page: ${page}, perPage: ${perPage}}, sort: {field: "${sortField}", ascending: ${ascending}}}) {
                    genreCategories {
                        id
                        name
                        color
                    }
                    count
                }
            }
        }
    `;
};

const GET_GENRE_CATEGORY = (id) => {
    return gql`
        query {
            genreCategory(id: "${id}") {
                id
                name
                color
            }
        }
    `
};

const CHANGE_GENRE_CATEGORY = gql`
    mutation ChangeGenreCategory($id: ID!, $name: String, $color: String) {
        changeGenreCategory(id: $id, name: $name, color: $color) {
            id
            name
            color
        }
    }
`;

const ADD_GENRE_CATEGORY = gql`
    mutation AddGenreCategory($regionId: ID!, $name: String!, $color: String) {
        addGenreCategory(regionId: $regionId, name: $name, color: $color) {
            id
            name
            color
        }
    }
`;

const DELETE_GENRE_CATEGORY = gql`
    mutation DeleteGenreCategory($genreCategoryId: ID!) {
        deleteGenreCategory(id: $genreCategoryId)
    }
`;

export const genreCategoryProvider = function(type, params, client, regionId) {
    switch(type) {
        case GET_LIST: {
            return client.query({
                query: GET_GENRE_CATEGORIES_BY_REGION(regionId, listOptionsFromParams(params))
            }).then(result => {
                return {
                    data: result.data.region.genreCategoriesList.genreCategories,
                    total: result.data.region.genreCategoriesList.count
                };
            });
        }
        case GET_ONE: {
            return client.query({
                query: GET_GENRE_CATEGORY(params.id)
            }).then(result => {
                return {
                    data: result.data.genreCategory
                };
            });
        }
        case GET_MANY: {
            // used for genre -> category selection
            return client.query({
                query: GET_GENRE_CATEGORY(params.ids[0])
            }).then(result => {
                return {
                    data: [result.data.genreCategory]
                };
            });
        }
        case UPDATE: {
            return client.mutate({
                variables: {
                    ...params.data
                },
                mutation: CHANGE_GENRE_CATEGORY
            }).then(result => {
                return {
                    data: result.data.changeGenreCategory
                }
            });
        }
        case CREATE: {
            return client.mutate({
                variables: {
                    regionId: regionId,
                    ...params.data
                },
                mutation: ADD_GENRE_CATEGORY
            }).then(result => {
                return {
                    data: result.data.addGenreCategory
                }
            });
        }
        case DELETE: {
            return client.mutate({
                variables: {
                    genreCategoryId: params.id
                },
                mutation: DELETE_GENRE_CATEGORY
            }).then(() => {
                return {
                    data: {id: params.id}
                };
            });
        }
        default: {

        }
    }
};