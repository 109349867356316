import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    body: {
        margin: 0
    },
    palette: {
        secondary: {
            main: '#7D9D97',
        },
    },
});