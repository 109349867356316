import React from 'react';
import { connect } from 'react-redux';
import {CurrentEventDays} from "./CurrentEventDays";
import {NoRegionSelected} from "./NoRegionSelected";

class RawDashboard extends React.Component {
    render() {
        if (!this.props.selectedRegionSlug) {
            return <NoRegionSelected/>
        }
        return <>
            <CurrentEventDays />
        </>
    }
}


export const Dashboard = connect(
    (state, ownProps) => {
        return {
            ...ownProps,
            selectedRegionSlug: state.selectedRegionSlug
        }
    },
    (dispatch, ownProps) => {
        return {

        }
    })(RawDashboard);