import React from "react";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import {withDataProvider, UPDATE} from 'react-admin';


class RawHideButton extends React.Component {
    onHideClick() {
        const { selectedRegionSlug, event, dataProvider} = this.props;

        const eventResource = "r/" + selectedRegionSlug + "/events";

        dataProvider(UPDATE, eventResource, { id: event.id, data: {id: event.id, isHidden: true} }, {
            refresh: true
        })
            .then(() => {
            })
            .catch(() => {

            });
    }

    render() {
        return <Tooltip title={"hide"}><IconButton aria-label="Hide" onClick={(e) => this.onHideClick(e)}>
            <ClearIcon />
        </IconButton></Tooltip>
    }

};

export const HideButton = withDataProvider(RawHideButton);