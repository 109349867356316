import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import MergeIcon from '@material-ui/icons/CallMerge';
import {withStyles} from '@material-ui/core/styles';
import {submit, isSubmitting} from 'redux-form';
import {connect} from 'react-redux';
import { Redirect } from 'react-router';
import {SimpleForm, fetchStart, fetchEnd, showNotification, SaveButton, ReferenceInput, AutocompleteInput, required} from "react-admin";
import {mergeVenue} from "../dataProvider/mergeVenue";

// we need to create a styled AutocompleteInput to bring it to the top of the Dialog
const TopAutocompleteInput = withStyles({
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 20000,
    }
})(AutocompleteInput);

class VenueMergeButtonRaw extends React.Component {
    state = {
        open: false
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleSaveClick = () => {
        const {submit} = this.props;
        submit('merge-venue-form');
    };

    handleSubmit = values => {
        const {fetchStart, fetchEnd, showNotification, venue} = this.props;

        const fromId = venue.id;
        const toId = values.venueId;

        if (fromId === toId) {
            showNotification("Cannot merge venue into itself", 'warning');
            return;
        }

        fetchStart();

        mergeVenue(fromId, toId).then(() => {
            showNotification("merged");
            this.setState({redirect: true});
        }).catch((e) => {
            showNotification(e.toString(), 'warning');
        }).finally(() => {
            fetchEnd();
        });
    }

    render() {
        const venuesResource = this.props.resource;
        if (this.state.redirect) {
            // redirecting to venues leads to a strange GET_ONE request by react-admin, so we redirect to the dashboard
            return <Redirect to={"/"}/>
        }
        const {isSubmitting} = this.props;

        return <div>
            <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                Merge into...
            </Button>
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Merge into another venue</DialogTitle>
                <DialogContent>
                    <DialogContentText>Merging this venue into another venue will migrate all aliases and the venue name
                        itself to the aliases of another venue. All events belonging to this venue will be linked to the
                        new venue.</DialogContentText>
                    <SimpleForm
                        form="merge-venue-form"
                        onSubmit={this.handleSubmit}
                        toolbar={null}
                        redirect={false}
                        undoable={false}
                    >
                        <ReferenceInput label="Venue" source="venueId" resource={venuesResource} reference={venuesResource} validate={[required()]}>
                            <TopAutocompleteInput optionText="name"
                                                  translateChoice={false}
                                                  limitChoicesToValue={true}
                            />
                        </ReferenceInput>
                    </SimpleForm>
                </DialogContent>

                <DialogActions>
                    <SaveButton
                        icon={<MergeIcon />}
                        label={"Merge"}
                        saving={isSubmitting}
                        onClick={this.handleSaveClick}
                    >Merge</SaveButton>
                </DialogActions>
            </Dialog>
        </div>
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('merge-venue-form')(state)
});

const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

export const VenueMergeButton = connect(mapStateToProps, mapDispatchToProps)(
    VenueMergeButtonRaw
);