import gql from 'graphql-tag';
import {createApiClient} from "./apiClient";


const MERGE_VENUE = gql`
    mutation MergeVenue($fromId: ID!, $toId: ID!) {
        mergeVenue(fromId: $fromId, toId: $toId) {
            id
        }
    }
`;

export const mergeVenue = function(fromId, toId) {
    return createApiClient().mutate({
        variables: {
            fromId: fromId,
            toId: toId
        },
        mutation: MERGE_VENUE
    });
}