import React from 'react';
import {FormControl, Select, MenuItem} from '@material-ui/core';
import { connect } from 'react-redux';
import {Redirect} from 'react-router';
import {getRegionsFromState} from "./helpers/getRegionsFromState";
import { withStyles } from '@material-ui/core/styles';


const StyledSelect = withStyles({
    selectMenu: {
        padding: 12
    }
})(Select);


class RegionSelect extends React.PureComponent {
    render() {
        if (this._redirect) {
            this._redirect = false;
            return <Redirect to={"/"}/>;
        }
        if (!this.props.regions) {
            return null;
        }
        return (<FormControl variant="outlined">
                    <StyledSelect
                        value={this.props.regionSlug ? this.props.regionSlug : "-"}
                        onChange={(e) => { this.props.regionSelected(e, this.props.regionSlug); this._redirect = true}}
                    >
                        <MenuItem key={"-"} value={"-"}>{"Select region"}</MenuItem>
                        {this.props.regions.map(region => {
                            return <MenuItem key={region.id} value={region.slug}>{region.name}</MenuItem>
                        })}
                    </StyledSelect>
            </FormControl>
        );
    }
}

export const DenkmalRegionSelect = connect(
    (state, ownProps) => {
        return {
            ...ownProps,
            regionSlug: state.selectedRegionSlug,
            regions: getRegionsFromState(state)
        }
    },
    (dispatch, ownProps) => {
        return {
            regionSelected: (event, prevRegionSlug) => {
                let newRegionSlug = event.target.value.length > 1 ?  event.target.value : null;

                if (prevRegionSlug !== newRegionSlug) {
                    dispatch({type: 'SELECTED_REGION_SLUG', regionSlug: newRegionSlug});
                }
            }
        }
    })(RegionSelect);